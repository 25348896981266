import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as MailIcon } from "images/email.svg";
import Tim from "images/team/tim.jpg";
import Bert from "images/team/bert.jpg";
import Floris from "images/team/floris.jpg";
import Gerd from "images/team/gerd.jpg";
import Katrien from "images/team/katrien.jpg";
import Lou from "images/team/lou.jpg";

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`mx-auto text-center`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`;
const Card = tw.div`mt-16 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`;
const CardImage = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
      background-color: #eee;
    `}
  ${tw`w-48 h-48 bg-contain bg-center rounded`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    text-align:center;
     ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
    margin-bottom:5px;
  }
  .expertise {
    ${tw`uppercase text-center text-xs text-gray-400 `}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
    margin-bottom:10px;
  9
`;

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  margin-top:0.5rem;
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}

    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`;

export default ({
  heading = "Meet the squadron",
  subheading = "Our Team",
  description = "We share a passion for electronics development and test, leveraging a “can do” attitude towards hardware, software, firmware, mechanics, integrated system solutions and management.",
  cards = [
    {
      imageSrc: Gerd,
      position: "General Manager & co-founder",
      name: "Gerd Van den Branden",
      expertise:
        " Industrialization processes & methodologies, Solutions architect, Quality, Operations",
      links: [
        {
          url: "https://www.linkedin.com/in/gerd-van-den-branden-ba65356/",
          icon: LinkedinIcon,
        },
        {
          url: "mailto:gvandenbranden@industrialise.be",
          icon: MailIcon,
        },
      ],
    },
    {
      imageSrc: Bert,
      position: "Hardware engineer & co-founder",
      name: "Bert Dewil",
      expertise: "System bring-up, debug, validation, high-speed interfaces",
      links: [
        {
          url: "https://www.linkedin.com/in/gerd-van-den-branden-ba65356/",
          icon: LinkedinIcon,
        },
        {
          url: "mailto:bdewil@industrialise.be",
          icon: MailIcon,
        },
      ],
    },
    {
      imageSrc: Tim,
      position: "Software Engineer & co-founder",
      name: "Tim Bruylants",
      expertise:
        "C, C++, Python, software framework & driver development, PCIe, USB, Linux, Windows, embedded software",
      links: [
        {
          url: "https://www.linkedin.com/in/tim-bruylants-4526271/",
          icon: LinkedinIcon,
        },
        {
          url: "mailto:tbruylants@industrialise.be",
          icon: MailIcon,
        },
      ],
    },
    {
      imageSrc: Floris,
      position: "Software Engineer",
      name: "Floris Lambrechts",
      expertise:
        "Python, software framework development, hardware interfaces, GUI development",
      links: [
        {
          url: "https://www.linkedin.com/in/florisla/",
          icon: LinkedinIcon,
        },
        {
          url: "mailto:flambrechts@industrialise.be",
          icon: MailIcon,
        },
      ],
    },

    {
      imageSrc: Lou,
      position: "Software & Characterization Engineer",
      name: "Lou Vervecken",
      expertise: "",
      links: [
        {
          url: "https://www.linkedin.com/in/florisla/",
          icon: LinkedinIcon,
        },
        {
          url: "mailto:lvervecken@industrialise.be",
          icon: MailIcon,
        },
      ],
    },
    {
      imageSrc: Katrien,
      position: "Office Manager",
      name: "Katrien Beyaert",
      expertise: "",
      links: [
        {
          url: "https://www.linkedin.com/in/florisla/",
          icon: LinkedinIcon,
        },
        {
          url: "mailto:kbeyaert@industrialise.be",
          icon: MailIcon,
        },
      ],
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage
                imageSrc={card.imageSrc}
                style={{ borderRadius: "50%" }}
              />
              <CardContent>
                <span className="name">{card.name}</span>

                <span className="position">{card.position}</span>

                <CardLinks>
                  {false &&
                    card.links.map((link, linkIndex) => (
                      <a key={linkIndex} className="link" href={link.url}>
                        <link.icon className="icon" />
                      </a>
                    ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
