import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import circuit from "../../images/circuit diagram left corner black.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/circuit.svg";

import DevelopIconImage from "images/lamp.svg";
import IndustrialiseIconImage from "images/rocket.svg";
import ConsultIconImage from "images/shake.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto `}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-secondary-500`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Decorator = ({ className }) => (
  <img src={circuit} className={className} />
);

const DecoratedBlob = styled(Decorator)`
  ${tw`hidden lg:flex`}

  right: 0;
  position: absolute;
  top: 0;
  margin: -30px;
  transform: scale(-1);
  width: 700px;
  opacity: 0.1;
`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw` text-center rounded-full p-5 flex-shrink-0`}
    margin: 0px auto;

    img {
      width: 4rem;
      height: 4rem;
      margin: auto;
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2 text-center`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-primary-100`}
    line-height:1.7;
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-10 transform translate-x-64 translate-y-48 `}
  transform:rotate(45deg);
`;

export default ({
  cards = null,
  heading = "What we have for you",
  subheading = "OUR SERVICES",
  description = "We specialize in back-end engineering and industrialization. We know which solutions and methodologies do and don’t work. Based on our experience in working with customers of all sizes, we can define, implement and deploy an optimal solution for each situation. We like to work with knowledge of the goals and current capabilities of customers, respecting the budgets and timelines. Are you short on resources, do you lack the expertise or do you want to scale-up? Then contact us at contact@industrialise.be.",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: DevelopIconImage,
      title: "Design & Dev",
      description:
        "Our engineers can support you during the electronic design and development phase for a characterization, test or qualification system.  We provide on-site support or deliver ready-to-use hardware and software modules for integration in your environment.",
    },
    {
      imageSrc: IndustrialiseIconImage,
      title: "Industrialization",
      description:
        "All activities that take place after the product design phase. With Industrialise we want to help our customers focus on their core business so they can excel at product innovation. Therefore we can assist in the complete back-end engineering phase that follows after the product design phase.    We have extensive expertise in the steps to industrialise your development, and we can help smoothen the transition between each of these steps.",
    },
    {
      imageSrc: ConsultIconImage,
      title: "Consultancy",
      description:
        "Industrialise can advise you during any step from product development to delivery. This can reduce your overall cost and improve your productivity. This frees up resources to focus on your core business and competitiveness.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <DecoratedBlob />
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card style={{ flexDirection: "column" }}>
              <div className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </div>

              <div className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </div>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
