import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo/Logo-Industrialise_Zwart.svg";
import circuit from "../../images/circuit diagram left corner.svg";
const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;
const Col = tw.div`flex items-center justify-center flex-row px-8 sm:flex-col`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-full`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} style={{ width: 400 }} />
          </LogoContainer>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <span
            style={{
              margin: 10,
              marginBottom: 30,
              textAlign: "center",
              fontSize: "18px",
              fonWeight: "bold",
              color: "rgba(255,72,0)",
            }}
          >
            <div>
              <a
                href="mailto:contact@industrialise.be"
                style={{ fontSize: 25, letterSpacing: 2 }}
              >
                contact@industrialise.be
              </a>
            </div>{" "}
            <div> +32 (0) 496 06 36 72</div>
          </span>
        </Row>
        <Row>
          <div>Industrialise BV</div>
          <div>Office: Mechelsesteenweg 120, 2860 Sint-Katelijne-Waver, Belgium</div>
          <div>H.O.: Mercatorlaan 12, 3191 Hever, Belgium</div>
          <div>VAT: BE 0742.558.655</div>
          <CopyrightText>
            &copy; Copyright 2022, Industrialise BV. All Rights Reserved.
          </CopyrightText>
          <img
            src={circuit}
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: 700,
              opacity: 0.1,
            }}
          />
        </Row>
      </Content>
    </Container>
  );
};
