import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, {
  NavLinks,
  NavLink,
  PrimaryLink,
} from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { SecondaryButton as SecondaryButtonBase } from "components/misc/Buttons.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";
import img from "images/intectiv-home-banner-dark.jpg";
const scrollTo = (element, to, duration) => {
  window.scroll({
    behavior: "smooth",
    left: 0,
    top: element.offsetTop,
  });
};
const RightColumn = styled.div`
  background-image: url(${img});
  background-repeat: repeat;

  width: 100%;
`;

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-primary-100 leading-snug xl:text-6xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-100 max-w-lg text-xl lg:text-xl`;
const SecondaryButton = tw(
  SecondaryButtonBase
)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const Section = tw.section`m-10 lg:m-10`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-100`;
const FeatureText = tw.p`ml-2 font-medium text-gray-100`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;

export default ({
  heading = "",
  description = "We assist tech companies in the transition from R&D activity to volume production and product launch. We do this by offering hardware modules, software frameworks and IP from our portfolio, or by providing tailored guidance and support.",
  imageSrc = "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
  imageDecoratorBlob = true,
  primaryButtonUrl = "mailto:contact@industrialise.be",
  primaryButtonText = "Get in touch",
  buttonRounded = true,
  features = [
    "Electronic design, prototyping and test",
    "Industrializing concepts into products",
    "Consultancy & guidance",
  ],
  testimonial = {
    quote:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    customerName: "Charlotte Hale",
    customerCompany: "Delos Inc.",
  },
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
      <NavLink onClick={() => scrollTo(document.getElementById("services"))}>
        Services
      </NavLink>
      <NavLink onClick={() => scrollTo(document.getElementById("team"))}>
        Team
      </NavLink>

      <NavLink onClick={() => scrollTo(document.getElementById("contact"))}>
        Contact
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}></NavLinks>,
  ];
  return (
    <>
      <Section>
        <Header links={navLinks} />
      </Section>
      <RightColumn>
        <ContentWithVerticalPadding style={{ paddingTop: 0, padding: 40 }}>
          <Row>
            <ImageColumn>
              <ImageContainer></ImageContainer>
              <Offsetbackground />
            </ImageColumn>
            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <SecondaryButton
                as="a"
                href={primaryButtonUrl}
                css={buttonRoundedCss}
              >
                {primaryButtonText}
              </SecondaryButton>

              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
          </Row>
        </ContentWithVerticalPadding>
      </RightColumn>
    </>
  );
};
